<template xmlns="http://www.w3.org/1999/html">
  <div class="content" style="margin-top: -50px">
    <div class="text-center">
      <vue-whatsapp-widget
        phoneNumber="+524421867547"
        companyName="tulum.party"
        textReply="Typically replies within 10-15 mins (9am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/08f3cb30-e877-11ec-9177-7506dda64181-2022-06-09-23.37.07.jpg?h=50"
      />

      <h1
        class="tulum-party-title"
        style="
          margin-bottom: 0;
          font-size: 2.3rem;
          letter-spacing: 2px;
          margin-top: 25px;
        "
      >
        TULUM CLUBS
      </h1>
      <br />
      <!--      <img-->
      <!--        align="center"-->
      <!--        v-lazy="mainImage"-->
      <!--        height="90px"-->
      <!--        style="border-radius:300px;box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%)"-->
      <!--      />-->
      <card
        class="card text-center col-sm-12 col-md-10 offset-md-1"
        style="z-index: 1"
      >
        <template slot="header">
          <h2
            class="tulum-party-subtitle"
            style="font-size: 0.9rem; z-index: 10; background-color: #100f0f"
          >
            Top Clubs
          </h2>
          <p class="text-center">
            We list the <strong>Top Tulum </strong> Clubs you do not want to
            miss this
            <strong class="label label-info font-weight-700">{{
              getCurrentMonth
            }}</strong>
          </p>
          <p style="font-size: 0.9rem; margin-top: 40px">
            <a
              href="https://www.visitmexico.com/en/quintana-roo/tulum"
              target="_blank"
            >
              Tulum´s</a
            >
            peculiar musical culture can be found everywhere from
            <a
              href="https://zamnafestival.com/"
              rel="nofollow noopener"
              target="_blank"
              >Big festivals</a
            >, <a href="/">outdoor parties</a> and premium events at high-end
            restaurants & clubs. No matter the day nor time, there's always a DJ
            & dancing crowd somewhere in Tulum, waiting for you.
          </p>
          <br />
          <br />
          <card class="card text-center" style="border: 2px solid black">
            <h3
              class="tulum-party-subtitle"
              style="
                text-transform: uppercase;
                font-size: 1.1rem;
                letter-spacing: 0.1rem;
                margin-top: -20px;
                font-weight: bold;
              "
            >
              <br />
              Best clubs to dance & have fun
            </h3>
            <h5 style="margin-top: -20px">
              We take you into the Tulum's elite club scene
            </h5>
            <div style="min-height: 51px">
              <img
                v-for="(row, idx) in venues.slice(0,6)"
                v-bind:alt="row.title"
                v-lazy="row.metadata.main_image.imgix_url + '?fit=clamp&h=50&w=50'"
                height="50px"
                width="50px"
                v-if="row.metadata.main_image"
              />
            </div>

            <hr>
            <div class="form-group">
              <select @change="goToClub($event)" class="select">
                <option>Select Club</option>
                <option
                  class=""
                  v-for="(row, idx) in sortedVenues"
                  :key="idx"
                  v-bind:value="row.slug"
                >
                  {{ row.title }}
                </option>
                <option value="dayClub">Day Options</option>
              </select>
            </div>
            <hr />

            <br />
            <RsvpViaDialog></RsvpViaDialog>
            <br />
            <small style="color: aquamarine">
              We operate from 9AM - 10PM / Answer within 15 mins</small
            >
            <br />
            <small>NO EXTRA FEE</small>
          </card>
        </template>
      </card>
    </div>
    <img
      v-lazy="bgPalm"
      v-if="showPalms"
      class="d-inline-sm d-md-none"
      style="
        position: fixed;
        left: 5%;
        bottom: 5%;
        opacity: 0.1;
        width: 100%;
        z-index: 0;
      "
    />
    <div class="row">
      <card
        class="card text-center col-sm-12 col-md-6"
        v-for="(row, idx) in venues"
        :key="idx"
        style="box-shadow: #000000 10px 10px 2px; padding: 40px"
      >
        <template slot="header">
          <h4 class="tulum-party-title" style="margin-top: -1px">
            {{ row.title }}
          </h4>
          <router-link :to="{ name: 'Club', params: { slug: row.slug } }">
            <img
              class="card-img"
              v-lazy="row.metadata.main_image.imgix_url + '?h=230&q=&fit=clamp&w=386&format=auto'"
              :alt="row.title"
              height="230px"
              v-if="row.metadata.main_image"
              style="box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%)"
            />
            <img
              v-if="row.metadata.logo && idx > 0"
              v-bind:class="row.metadata.code.toLowerCase()"
              v-lazy="row.metadata.logo.imgix_url + '?h=45&w=85&auto=format'"
              height="45px"
              width="85px"
              style="margin-top: 20px; max-width: 100px"
              :alt="row.title"
            />

            <div v-if="idx === 0" style="margin-top: 30px">
              <span class="badge-pill badge-primary">
                <img
                  v-lazy="activityImg"
                  alt="tulum todays club"
                  v-if="idx === 0"
                  height="30px"
                  style="
                    border: 1px solid yellow;
                    left: -4px;
                    position: relative;
                  "
                />
                High Activity Day
              </span>
            </div>
            <hr class="brand-title-black" style="height: 1px" />
          </router-link>

          <div v-if="row.metadata.tags" style="margin-bottom: 20px">
            <span
              v-for="(tag, idx) in row.metadata.tags.split('|')"
              class="badge-category"
              style="font-weight: bold"
              :key="idx"
              ><strong>{{ tag }}</strong></span
            >
          </div>
          <router-link
            :to="{ name: 'Club', params: { slug: row.slug } }"
            class="badge-pill"
            style="
              background-color: #23d5ab;
              margin-bottom: 5px;
              color: black !important;
            "
            v-if="incomingClubParties[row.title]"
            >{{ incomingClubParties[row.title].length }} Upcoming Events
          </router-link>
          <br v-if="incomingClubParties[row.title]" />
          <hr class="brand-title-black" style="height: 4px" />
          <div
            v-html="row.metadata.description"
            class="md-small-hide"
            style="color: #fff !important; margin-bottom: 10px"
          ></div>
          <br />
          <ClubDetails :config="ClubTagsConfig[row.slug]"></ClubDetails>
        </template>

        <template slot="default">
          <router-link
            :to="{ name: 'Club', params: { slug: row.slug } }"
            class="btn font-weight-bold btn-primary"
          >
            + CLUB INFO & EVENTS
          </router-link>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import ClubDetails from '@/components/Clubs/ClubDetailsTable';
// import ClubFilter from "@/components/ClubFilter";
import clubsTableConfig from '../../common/clubsTableConfig';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
  );
const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
  );

export default {
  name: 'Clubs.vue',
  data: () => {
    return {
      showAll: false,
      showPalms: false,
      showModal: true,
      ClubTagsConfig: { tags: [] },
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      clubsSpirt:
        'https://imgix.cosmicjs.com/7b95aff0-7b8f-11ec-8c2f-a300cf8a78dc-image.png',
      activityImg:
        'https://imgix.cosmicjs.com/3f6794f0-6cfe-11ee-a874-d142b6892265-activity.png',
      marcianoRosa:
        'https://imgix.cosmicjs.com/4701cb20-b87f-11ec-a7cc-25c8f0ace24d-marciano-rosa.png',
      mainImage:
        'https://imgix.cosmicjs.com/031c4bf0-e6e8-11ec-9447-f98173199613-telegram-cloud-photo-size-1-4972161472985672258-y.jpg?duotone=black,ff6491&duotone-alpha=100',
      questionClub: '',
      showReserve: false,
      questionType: 'info',
    };
  },
  metaInfo: () => ({
    title:
      "Tulum Top Clubs | Reservations · Events · Tickets · Clubs to Dance & Have Fun.",
    link: [{ rel: 'canonical', href: 'https://www.tulum.party/tulum-clubs' }],
    meta: [
      {
        name: 'description',
        content:
          ' Information & Reservations for the best clubs in Tulum, Mexico.' +
          'Vagalume Tulum, Taboo Tulum, Mia Beach Club, Mia Tulum, Bonbonniere, Bagatelle, Confessions, Papaya Playa, Rosa Negra, Taboo, Gitano ',
      },
      {
        name: 'keywords',
        content:
          'tulum nightclubs, tulum beach clubs, tulum nightlife, tulum dj events, tulum party scene, tulum music venues, tulum dance clubs, tulum bars, tulum clubs calendar, tulum events calendar, tulum club reservations, tulum vip tables, tulum bottle service, tulum bachelorette party, tulum bachelor party, tulum vacation activities, tulum things to do at night',
      },
      {
        property: 'og:title',
        content: `Top Tulum Clubs to Dance & Have Fun. Tickets & Smart RSVP.`,
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:locality', content: 'Tulum' },
      { property: 'og:country-name', content: 'Mexico' },
      {
        property: 'og:description',
        content: `Top Clubs Information & Reservations. Make a Reservation on Tulum's top Clubs. Party Ninjas & Quick Booking experts`,
      },
      {
        property: 'og:image',
        content:
          'https://imgix.cosmicjs.com/3c6c5b60-96ea-11eb-b593-972a7dbc1054-papaya-playa-small.png',
      },
    ],
  }),
  components: {
    ClubDetails,
    // ClubFilter,
    RsvpViaDialog,
    VueWhatsappWidget,
  },
  computed: {
    incomingParties: {
      get() {
        return this.$store.state.incomingParties;
      },
    },
    incomingClubParties: {
      get() {
        return this.$store.state.incomingClubParties;
      },
    },
    venues: {
      get() {
        return this.$store.state.venues;
      },
    },
    tags: {
      get() {
        return [
          ...new Set(
            this.$store.state.venues
              .map((v) => v.metadata.tags.split('|'))
              .flat()
          ),
        ];
      },
    },
    categories: {
      get() {
        let h = {};
        this.$store.state.venues.forEach((venue) => {
          venue.metadata.tags.split('|').forEach((tag) => {
            if (h[tag]) {
              h[tag].push(venue.title);
            } else {
              h[tag] = [venue.title];
            }
          });
        });
        return h;
      },
      // return this.$store.state.venues.map(x => x.metadata.tags.split("|")).flat().filter((v,i,a) => a.indexOf(v) === i);
    },
    getCurrentMonth: {
      get() {
        let d = new Date();
        return (
          [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ][d.getMonth()] +
          ' ' +
          d.getFullYear()
        );
      },
    },
    sortedVenues: {
      get() {
        return [...this.$store.state.venues].sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      },
    },
  },
  created() {
    // alert( Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.$store.dispatch('getVenues', { start: 0, limit: 14});
    this.$store.dispatch('getParties');
    this.ClubTagsConfig = clubsTableConfig;
    window.history.scrollRestoration = 'manual';
  },
  updated() {
    // this.ClubTagsConfig = clubsTagsConfig;
    setTimeout(() => (this.showPalms = true), 5000);
  },
  methods: {
    paginate() {
      this.showAll = true;
      this.$store.dispatch('getVenues', { start: 12, limit: 20 });
    },
    goToClub(event) {
      let path = '';
      if(event.target.value === 'dayClub') {
        path = '/tulum-day-beach-clubs';
      } else {
        path = '/tulum-clubs/' + event.target.value;
      }
      this.$router.push({
        path: path,
        replace: true,
      });

      this.$rollbar.info('QUICK CLUB: ' + path);
    },
  },
};
</script>
